import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-free-investment',
  templateUrl: './tax-free-investment.component.html',
  styleUrls: ['./tax-free-investment.component.css']
})
export class TaxFreeInvestmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
