import { Component, OnInit, Input } from "@angular/core";
import { debugOutputAstAsTypeScript } from "@angular/compiler";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-feature-box",
  templateUrl: "./feature-box.component.html",
  styleUrls: ["./feature-box.component.css"]
})
export class FeatureBoxComponent implements OnInit {
  @Input() DataSets;
  is_saraf = false;
  constructor(
    private router: Router,
  ) {
    var urlArr = this.router.url.split("/");
    if(urlArr[1] && urlArr[1] == "sharia-investor") {
      this.is_saraf = true;
    }
  }

  ngOnInit() {
    if(this.DataSets.acf.buttonurl == "new-to-investing" && this.is_saraf == true) {
      this.DataSets.acf.buttonurl = "new-to-shariah-investing";
    }
    window.scrollTo(0,0);
  }
}
