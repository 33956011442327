import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable(
  { providedIn: 'root' }
)

export class GlobalService {

  public legalChanged: Subject<string> = new Subject<string>();
  constructor() {

  }
}