import { Component, OnInit, Input } from "@angular/core";
import { WPAPIService } from "./../../../../services/wpapi.service";
@Component({
  selector: "app-up-quaterly-library",
  templateUrl: "./up-quaterly-library.component.html",
  styleUrls: ["./up-quaterly-library.component.css"]
})
export class UpQuaterlyLibraryComponent implements OnInit {
  @Input() getQuaterlyImage;
  categories;
  selectedItem;
  imagePosts;
  currentSubCate;
  isfirst = false;
  loading = false;
  constructor(private wpservice: WPAPIService) {}
  addItem = {
      "id": 335,
      "count": 1,
      "description": "",
      "link": "https://www.camissa-am.com/cms/category/up-quarterly-library/2021-up-quarterly-library/",
      "name": "2021",
      "slug": "2021-up-quarterly-library",
      "taxonomy": "category",
      "parent": 31,
      "meta": [],
      "acf": {
          "meet_our_leaders": null
      },
      "_links": {
          "self": [
              {
                  "href": "https://www.camissa-am.com/cms/wp-json/wp/v2/categories/335"
              }
          ],
          "collection": [
              {
                  "href": "https://www.camissa-am.com/cms/wp-json/wp/v2/categories"
              }
          ],
          "about": [
              {
                  "href": "https://www.camissa-am.com/cms/wp-json/wp/v2/taxonomies/category"
              }
          ],
          "up": [
              {
                  "embeddable": true,
                  "href": "https://www.camissa-am.com/cms/wp-json/wp/v2/categories/31"
              }
          ],
          "wp:post_type": [
              {
                  "href": "https://www.camissa-am.com/cms/wp-json/wp/v2/posts?categories=335"
              }
          ],
          "curies": [
              {
                  "name": "wp",
                  "href": "https://api.w.org/{rel}",
                  "templated": true
              }
          ]
      }
  };
  ngOnInit() {
    window.scrollTo(0,0);
    this.wpservice.getCategory("?parent=31").subscribe(categories => {
      this.categories = categories;
      this.categories.push(this.addItem);
      this.categories.sort((a, b) => b.name - a.name);
      this.selectedItem = categories[0];
      this.wpservice
        .getQuaterlyImagePostFromCategory(`?categories=${this.selectedItem.id}`)
        .subscribe(imagePost => {
          this.loading = false;
          this.imagePosts = imagePost;
          //console.log("image", this.imagePosts);
          this.isfirst = true;
          this.currentSubCate = this.selectedItem;
        });
    });

    // console.log(this.selectedItem);
  }
  getYearPost(category, event, toggle) {
    this.loading = true;
    if (this.currentSubCate) {
      if (category == this.currentSubCate) {
        this.isfirst = !toggle;
      } else {
        this.isfirst = true;
      }
      this.currentSubCate = category;
    } else {
      this.isfirst = !toggle;
      this.currentSubCate = category;
    }
    this.selectedItem = category;
    this.wpservice
      .getQuaterlyImagePostFromCategory(`?categories=${category.id}`)
      .subscribe(imagePost => {
        this.loading = false;
        this.imagePosts = imagePost;
        //console.log(this.imagePosts);
      });
  }
}
