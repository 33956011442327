import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService } from 'src/services/global.service';

@Component({
  selector: 'app-legal-faq',
  templateUrl: './legal-faq.component.html',
  styleUrls: ['./legal-faq.component.css']
})
export class LegalFaqComponent implements OnInit, OnChanges {
  @Input() faqs;
  @Input() fromStatusFaq;
  @Input() insightStatus;
  @Input() glossaryStatus;
  @Input() fromNewInvesting;
  isfirst = false;
  tab = 0;
  currentFaq;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.globalService.legalChanged.subscribe(result => {
      if (result === "privacy") {
        this.tab = 2;
      }
      else if (result === "cookie") {
        this.tab = 3;
      }
      window.scrollTo(0, 0);
      this.currentFaq = this.faqs[this.tab];
      this.isfirst = true;
    });
    this.setPrivacyCookie();
  }

  ngOnChanges() {
    if (this.route.snapshot.paramMap.get('tab') === "privacy") {
      this.tab = 2;
    }
    else if (this.route.snapshot.paramMap.get('tab') === "cookies") {
      this.tab = 3;
    }
    this.currentFaq = this.faqs[this.tab];
    this.isfirst = true;
  }

  setPrivacyCookie() {
    if (this.route.snapshot.paramMap.get('tab') === "privacy") {
      this.tab = 2;
    }
    else if (this.route.snapshot.paramMap.get('tab') === "cookie") {
      this.tab = 3;
    }
    window.scrollTo(0, 0);
    this.currentFaq = this.faqs[this.tab];
    this.isfirst = true;
  }

  getClicked(faq, toggle) {
    if (this.currentFaq) {
      if (this.currentFaq != faq) {
        this.isfirst = true;
      } else {
        this.isfirst = !toggle;
      }
    }
    this.currentFaq = faq;
  }
}
